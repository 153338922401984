import PageContents from '@/types/PageContents';

import WCessysVideo from './Wirbelsauule/Cessys-Video.html';
import WILessysVideo from './Wirbelsauule/iLessys-Video.html';
import WMultizyteVideo from './Wirbelsauule/Multizyte-Video.html';
import WTessysVideo from './Wirbelsauule/Tessys-Video.html';

import ECamsourceLed from './EndoTower/Camsource-LED.html';
import ECamsourceVideo from './EndoTower/Camsource-Video.html';

// import EIntro from './EndoTower/EndoTower-Intro-Text.html';
import EIntroVideo from './EndoTower/EndoTower-Intro-Video.html';

import EEndvaporVideo from './EndoTower/Endovapor-Video.html';
import EEndvapor from './EndoTower/Endovapor.html';

import EIntracsVideo from './EndoTower/Intracs-Video.html';
import EIntracs from './EndoTower/Intracs.html';

import EShrillVideo from './EndoTower/Shrill-Video.html';
import EShrill from './EndoTower/Shrill.html';

import EVersiconVideo from './EndoTower/Versicon-Video.html';
import EVersicon from './EndoTower/Versicon.html';

import EVitegraVideo from './EndoTower/Vitegra-Image-Video.html';
import EVitegra from './EndoTower/Vitegra.html';

// const distanceX = 7.5;

const videoModalSize = {
  height: 'calc(315px + 4rem)',
  width: 'calc(560px + 4rem)',
};

const pageContent: PageContents[] = [
  // {
  //   button: {
  //     top: '23%',
  //     left: '72%',
  //     type: 'video',
  //     content: 'New Intracs',
  //     textAlign: 'left',
  //   },
  //   modal: {
  //     ...videoModalSize,
  //     content: EIntroVideo,
  //   },
  // },

  {
    button: {
      top: '41%',
      left: '85%',
      type: 'video',
    },
    modal: {
      ...videoModalSize,
      content: EVitegraVideo,
    },
  },
  {
    button: {
      top: '41%',
      left: '89%',
      type: 'open',
      content: 'Vitegra',
    },
    modal: {
      content: EVitegra,
    },
  },

  {
    button: {
      top: '46%',
      left: '70%',
      type: 'video',
      content: 'Camsource LED',
      textAlign: 'left',
    },
    modal: {
      ...videoModalSize,
      content: ECamsourceVideo,
    },
  },
  {
    button: {
      top: '46%',
      left: '74%',
      type: 'open',
    },
    modal: {
      content: ECamsourceLed,
    },
  },

  {
    button: {
      top: '53%',
      left: '85%',
      type: 'video',
    },
    modal: {
      ...videoModalSize,
      content: EIntracsVideo,
    },
  },
  {
    button: {
      top: '53%',
      left: '89%',
      type: 'open',
      content: 'Intracs',
    },
    modal: {
      content: EIntracs,
    },
  },

  {
    button: {
      top: '57.5%',
      left: '70%',
      type: 'video',
      content: 'Shrill',
      textAlign: 'left',
    },
    modal: {
      ...videoModalSize,
      content: EShrillVideo,
    },
  },
  {
    button: {
      top: '57.5%',
      left: '74%',
      type: 'open',
    },
    modal: {
      content: EShrill,
    },
  },

  {
    button: {
      top: '66%',
      left: '70%',
      type: 'video',
      content: 'Endovapor 2',
      textAlign: 'left',
    },
    modal: {
      ...videoModalSize,
      content: EEndvaporVideo,
    },
  },
  {
    button: {
      top: '66%',
      left: '74%',
      type: 'open',
    },
    modal: {
      content: EEndvapor,
    },
  },

  {
    button: {
      top: '73%',
      left: '85%',
      type: 'video',
    },
    modal: {
      ...videoModalSize,
      content: EVersiconVideo,
    },
  },
  {
    button: {
      top: '73%',
      left: '89%',
      type: 'open',
      content: 'Versicon',
    },
    modal: {
      content: EVersicon,
    },
  },

  {
    button: {
      top: '45%',
      left: '37%',
      type: 'video',
      textAlign: 'bottom',
      content: 'iLESSYS',
    },
    modal: {
      ...videoModalSize,
      content: WILessysVideo,
    },
  },
  {
    button: {
      top: '43%',
      left: '47%',
      type: 'video',
      content: 'CESSYS',
    },
    modal: {
      ...videoModalSize,
      content: WCessysVideo,
    },
  },
  {
    button: {
      top: '40%',
      left: '34%',
      type: 'video',
      textAlign: 'top',
      content: 'TESSYS',
    },
    modal: {
      ...videoModalSize,
      content: WTessysVideo,
    },
  },
  {
    button: {
      top: '43%',
      left: '27%',
      type: 'video',
      textAlign: 'top',
      content: 'MultiZYTE',
    },
    modal: {
      ...videoModalSize,
      content: WMultizyteVideo,
    },
  },
];

export default pageContent;
