







import {
  Component,
  Vue,
  Emit,
  Prop,
} from 'vue-property-decorator';

import CircleButton from '@/components/CircleButton.vue';

@Component({
  components: {
    CircleButton,
  },
})
export default class Modal extends Vue {
  public parsedContent= '';

  @Prop() private x!: number;

  @Prop() private y!: number;

  @Prop() private width!: string;

  @Prop() private height!: string;

  @Prop() private closeBtn!: boolean;

  @Prop() private htmlContent!: string;

  @Emit()
  close(e: MouseEvent) {
    return e;
  }

  get posX() {
    return this.x ? `${this.x}%` : '10%';
  }

  get posY() {
    return this.y ? `${this.y}%` : '10%';
  }

  get posWidth() {
    return this.width ? this.width : '50%';
  }

  get posHeight() {
    return this.height ? this.height : null;
  }
}
