













import {
  Component, Prop, Vue, Emit,
} from 'vue-property-decorator';

@Component
export default class CircleButton extends Vue {
  @Prop() private type!: 'close' | 'video' | 'open' | 'mail';

  @Prop() private pulsate!: boolean;

  @Prop() private textAlign!: 'left' | 'right' | 'top' | 'bottom';

  @Prop() private url!: string;

  @Prop() private index!: number;

  public show = false;

  mounted() {
    if (this.index !== undefined) {
      setTimeout(() => {
        this.show = true;
      }, (this.index + 1) * 300);
    } else {
      this.show = true;
    }
  }

  @Emit()
  click(e: MouseEvent) {
    if (this.url) {
      return false;
    }
    return e;
  }

  get targetUrl() {
    return this.url || null;
  }
}
